.home_content {
    display: flex;
    flex-direction: column;
    padding-bottom: 0px;
}

.home_section {
    padding: 10px 0px 10px 20px;
}

.home_section_title {
    margin: 0;
    color: var(--text-second);
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-400);
}

.home_section_content {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.user_section_content {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}

@media (max-width: 768px){
    .home_content {
        padding-bottom: 17vh;
    }
}