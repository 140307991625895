.modal_signin_container {
    width: 100%;
    height: 100%;
    background: var(--modal-background);
    position: fixed;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_signin_container .title-text {
    display: flex;
    width: 200%;
}

.modal_signin_container .title {
    width: 50%;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    color: var(--text-color);
}

.modal_signin_container .slide-controls {
    position: relative;
    display: flex;
    height: 50px;
    width: -webkit-fill-available;
    overflow: hidden;
    margin: 30px 0 10px 0;
    justify-content: space-between;
    border: 1px solid lightgrey;
    border-radius: 10px;
}

.modal_signin_content {
    width: 100%;
    padding: 20px;
}

.slide-controls .slide {
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.6s ease;
}

.slide-controls .slider-tab {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    z-index: 0;
    border-radius: 10px;
    background: -webkit-linear-gradient(left, #003366, #004080, #0059b3, #0073e6);
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"] {
    display: none;
}

#signup:checked~.slider-tab {
    left: 50%;
}

#signup:checked~label.signup {
    color: #fff;
    cursor: default;
    user-select: none;
}

#signup:checked~label.login {
    color: #fff;
}

#login:checked~label.signup {
    color: #fff;
}

#login:checked~label.login {
    cursor: default;
    user-select: none;
}

.wrapper .form-container {
    width: 100%;
    overflow: hidden;
}

.form-container .form-inner {
    display: flex;
    width: 200%;
}

.form-container .form-inner form {
    width: 50%;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-inner form .field {
    height: 50px;
    width: -webkit-fill-available;
    margin-top: 20px;
}

.form-inner form .field input {
    height: 100%;
    width: -webkit-fill-available;
    outline: none;
    padding-left: 15px;
    border-radius: 10px;
    border: 1px solid lightgrey;
    font-size: 17px;
    transition: all 0.3s ease;
}

.form-inner form .field input:focus {
    border-color: #1a75ff;
}

.form-inner form .field input::placeholder {
    color: #999;
    transition: all 0.3s ease;
}

form .field input:focus::placeholder {
    color: #1a75ff;
}

.form-inner form .pass-link {
    margin-top: 5px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
    color: #1a75ff;
    text-decoration: none;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
    text-decoration: underline;
}

form .btn {
    height: 50px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

form .btn .btn-layer {
    height: 100%;
    width: 300%;
    position: absolute;
    left: -100%;
    background: -webkit-linear-gradient(right, #003366, #004080, #0059b3, #0073e6);
    border-radius: 10px;
    transition: all 0.4s ease;
    ;
}

form .btn:hover .btn-layer {
    left: 0;
}

form .btn input[type="submit"] {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
    background: none;
    border: none;
    color: #fff;
    padding-left: 0;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}

.input-file {
    position: relative;
    display: inline-block;
    width: -webkit-fill-available;
}

.input-file span {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    vertical-align: middle;
    color: rgb(255 255 255);
    text-align: center;
    border-radius: 10px;
    background-color: #419152;
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
    width: -webkit-fill-available;
}

.input-file input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
}

.input-file input[type=file]:focus+span {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.input-file:hover span {
    background-color: #59be6e;
}

.input-file:active span {
    background-color: #2E703A;
}

.input-file input[type=file]:disabled+span {
    background-color: #eee;
}

.close-modal {
    margin: 0px;
    padding: 0px;
    text-align: right;
    font-size: 35px;
    color: #fff;
    cursor: pointer;
}

.pass-link {
    cursor: pointer;
    color: var(--text-color);
}