.modal-success {
    position: fixed;
    width: 40vh;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.modal-content-success {
    display: flex;
    justify-content: center;
    align-items: center;
}