.custom-audio-player {
    display: flex;
    align-items: center;
    gap: 10px;
}

.play-pause-button {
    background: none;
    border: none;
    cursor: pointer;
}

.demo-text {
    font-size: 16px;
    color: #333;
}