.pay_modal {
    top: 0;
    height: 100%;
    width: 100%;
    background: #232323fa;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pay_modal_content {
    padding: 0px 20px;
    width: -webkit-fill-available;
    padding-bottom: 50px;
}

.pay_modal .slide-controls {
    position: relative;
    display: flex;
    height: 50px;
    width: -webkit-fill-available;
    overflow: hidden;
    margin: 30px 0 10px 0;
    justify-content: space-between;
    border: 1px solid lightgrey;
    border-radius: 15px;
}

.pay_modal .form-container {
    width: 100%;
    overflow: hidden;
}

.pay_input_field {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background-color: #d3d3d3;
    /* Light grey background */
    font-size: 16px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

/* Text Below Input */
.pay_text {
    color: #fff;
    margin: 0px;
    width: 400px;
    font-size: 16px;
    font-weight: 300;
}

.pay_modal_text {
    color: #fff;
    margin-bottom: 10px;
    width: 400px;
    font-size: 16px;
    font-weight: 300;
}

/* Checkbox Container */
.pay_checkbox_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Hide Native Checkbox */
.pay_checkbox_container input[type="checkbox"] {
    display: none;
}

/* Checkbox Label with Circle */
.pay_checkbox_container label {
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Check Icon Styling */
.pay_check_icon {
    width: 16px;
    height: 16px;
    display: none;
}

/* Show Check Icon When Checked */
.pay_checkbox_container input[type="checkbox"]:checked+label .pay_check_icon {
    display: block;
}

/* Save Button Styling */
.save_button {
    background-color: #0b3d91;
    /* Dark blue background */
    color: white;
    border: none;
    padding: 15px 0;
    width: 100%;
    border-radius: 12px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Hover Effect on Save Button */
.save_button:hover {
    background-color: #09468f;
}

.pay_check_content {
    display: flex;
    width: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
}

@media (min-width: 768px) {
    .pay_modal_content {
        width: 400px;
    }
}