.signin_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary-color);
    color: var(--text-second);
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 14;
}

.signin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.form-control {
    width: 30vh;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 10px;
    border: none;
    margin: 5px;
}

.btn {
    display: flex;
    background-color: var(--secondary-color);
    color: #fff;
    margin: 20px;
    width: 35vh;
    height: 6vh;
    font-size: 1rem;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-500);
}

.btn img {
    margin-right: 5px;
}

.email_icon {
    filter: brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(7488%) hue-rotate(184deg) brightness(105%) contrast(105%);
    padding-right: 5px;
    width: 17px;
}

.google_icon {
    width: 30px;
}

.error-message {
    color: red;
}

.message {
    position: fixed;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(119, 119, 119);
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    opacity: 1;
    animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}