.books_container {
    margin: 0 auto;
}

.search_input {
    padding: 8px;
    margin: 20px;
    border: 1px solid var(--text-second);
    border-radius: var(--border-radius);
    width: -webkit-fill-available;
    font-size: 16px;
    background: var(--primary-color);
    color: var(--text-second);
}

.search_input::placeholder {
    color: var(--text-second);
    text-align: center;
}

.genre_container {
    display: flex;
    padding: 10px 0;
    margin: 0px 0px 0px 20px;
    overflow-x: auto;
}

.genre_button {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: var(--border-radius);
    background-color: transparent;
    color: var(--secondary-color);
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    white-space: nowrap;
    flex-shrink: 0;
}

.genre_button.active {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--text-color);
}

.genre_button:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--text-color);
}

.books_content {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    position: relative;
}

.swiper_slide {
    width: 127px;
    height: 172px;
    margin: 15px 10px;
    transition: transform 0.5s;
    position: absolute;
    top: 0;
    filter: blur(5px);
}

.swiper_slide_active {
    margin: 0px 0px;
    color: var(--text-second);
    filter: none;
}

.swiper_slide_active .card_details {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 35vh;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    max-height: 30vh;
    overflow-y: auto;
}

.card_details {
    display: none;
}

.card_header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 15px;
}

.card_title {
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-400);
    margin: 0;
    padding: 0;
    text-align: center;
}

.card_sound {
    text-align: center;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-400);
}

.card_image {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: var(--border-radius);
}

.card_fooder {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 20vh;
    justify-content: center;
}   

.booksIconButton {
    background: transparent;
    border: none;
    width: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.booksIconButton img {
    filter: var(--icon-color-filter2);
}

.count {
    font-size: 20px;
    margin-left: 3px;
    color: var(--secondary-color);
}

.comments-section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
    width: -webkit-fill-available;
}

.comment-header {
    display: flex;
    align-items: center;
}

.comment {
    background: #222f3c4d;
    margin: 1rem 0rem;
    padding: 3px;
    border-radius: 10px;
}

.comment-avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.comment-author {
    font-weight: bold;
}

.inputSection {
    display: flex;
    flex-direction: row;
}

.commentsButton {
    background: var(--secondary-color);
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 20px;
}

.commentInput {
    background: transparent;
    padding: 10px;
    border: none;
    color: #fff;
    width: -webkit-fill-available;
}

.commentInput:focus {
    border: 1px solid #fff;
    border-radius: 10px;
}

.listen_demo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.read-button {
    background-color: transparent;
    border: none;
    border-radius: 20px;
}

.buy-button {
    background-color: green;
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    color: white;
    margin: 10px;
    font-size: 22px;
    margin-right: 30px;
}

.price {
    font-weight: bold;
    font-size: 22px;
}

.play_icon {
    width: 35px;
    height: 35px;
}

.pay-button {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}