.home-container {
    margin: 0 auto;
    background-color: var(--primary-color);
}

.button_name {
    color: white;
    margin: 0px;
    text-decoration: none;
}

.author-card {
    margin: 15px 10px 10px 0px;
    text-align: center;
}

.author-image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 10px;
}

.author-name {
    margin: 0;
    color: #fff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 120px;
}

.close-button-home {
    background: #ffffff00;
    border: none;
    position: fixed;
    right: 20px;
    top: 59vh;
}

@media (min-width: 768px) { 
    .main-content {
        padding-bottom: 0vh;
        padding-top: 10vh;
    }
}