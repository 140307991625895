.footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 30;
    border-radius: 15px 15px 0px 0px;
    background-color: var(--accent-color);
    max-height: 400px;
    overflow-y: auto;
}

.desktop_icons {
    display: none;
}

.footer_nav {
    display: flex;
    flex-direction: row;
}

.nav_list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0px;
}

.nav_item {
    margin: 20px;
}

.nav_icon {
    width: 28px;
    height: 28px;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(42%) sepia(77%) saturate(18%) hue-rotate(16deg) brightness(96%) contrast(87%);
}

.nav_active {
    filter: brightness(0) saturate(100%) invert(59%) sepia(93%) saturate(5233%) hue-rotate(209deg) brightness(100%) contrast(96%);
}

.nav_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.desktop_show {
    display: none;
}

.desktop_hide {
    display: flex;
}

.desktop_show .fooder_control {
    position: static;
    margin: 20px 0px;
    width: 100%;
}

@media (min-width: 768px) {
    .desktop_hide {
        display: none;
    }

    .nav_item {
        margin: 0px;
    }

    .nav_esti_logo {
        height: 32px;
        margin-right: 50px;
    }

    .desktop {
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        z-index: 30;
        border-radius: 0px 0px 10px 10px;
        background-color: #16191B;
        max-height: 100px;
        overflow-y: auto;
    }

    .desktop_show {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: -webkit-fill-available;
        padding: 0px 20px;
        background-color: var(--accent-color);
    }

    .desktop_left_section {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
    }

    .desktop_left_section li {
        list-style: none;
        color: #B2B2B2;
        margin-right: 30px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
}