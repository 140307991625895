:root {
    /* Colors */
    --primary-color: #FFFFFF;
    --secondary-color: #3C78F9;
    --accent-color: #DFDFDF;
    --accent-second: #797979;
    --text-color: #FFFFFF;
    --text-second: #222222;
    --modal-background: #232323d0;

    /* Typography */
    --font-size-24: 24px;
    --font-size-16: 16px;
    --font-size-14: 14px;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;

    /* Spacing */
    --spacing-small: 8px;
    --spacing-medium: 16px;
    --spacing-large: 24px;

    /* Border Radius */
    --border-radius: 10px;

    --highlight-color: #00FFCC;
    /* Accent for active and hover states */
    --border-radius: 10px;
    --box-shadow: 0 4px 8px rgba(0, 255, 204, 0.3);

    --icon-color-filter: brightness(0) saturate(100%) invert(14%) sepia(23%) saturate(901%) hue-rotate(169deg) brightness(88%) contrast(88%);
    --icon-color-filter2: brightness(0) saturate(100%) invert(45%) sepia(58%) saturate(4558%) hue-rotate(209deg) brightness(100%) contrast(96%);
}

.click {
    cursor: pointer;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-container h1 {
    color: #fff;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #2F3A47;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

::-webkit-scrollbar-track {
    background: var(--secondary-color);
}

::-webkit-scrollbar-thumb {
    background: #191C1E;
}

::-webkit-scrollbar-thumb:hover {
    background: #fff;
}

.error {
    color: red;
    text-align: center;
    margin: 10px 0px;
}

.link {
    text-decoration: none;
    color: #228df9;
}

.error-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--secondary-color);
    color: var(--text-color);
    padding: var(--modal-padding);
    border-radius: 10px;
    box-shadow: var(--modal-shadow);
    z-index: 1000;
    width: 90%;
    max-width: 400px;
    text-align: center;
    padding: 20px 0px;
}

.error-modal h2 {
    color: var(--text-color);
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.error-modal p {
    margin: 10px 0;
    font-size: var(--font-size-16);
}

.error-modal button {
    margin-top: 20px;
    padding: 10px 20px;
    background: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: var(--modal-radius);
    cursor: pointer;
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-400);
    transition: background-color 0.3s ease;
}

.error-modal button:hover {
    background: var(--primary-color-hover, #0056b3);
}

@media (max-width: 768px) {
    ::-webkit-scrollbar {
        width: 1px;
        height: 0px;
    }

    ::-webkit-scrollbar-track {
        background: var(--secondary-color);
    }

    ::-webkit-scrollbar-thumb {
        background: #191C1E;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }
}