.book_card {
    position: relative;
    margin: 15px 10px 10px 0px;
    width: 127px;
    cursor: pointer;
}

.book_image {
    width: 127px;
    height: 172px;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.book_name {
    margin: 0px;
    color: var(--text-second);
    text-align: center;
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-400);
}

.paid_icon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 6;
}

.play_button {
    position: absolute;
    z-index: 6;
    top: 60%;
    background-color: transparent;
    border: none;
    cursor: pointer;
}