input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: -webkit-fill-available;
    background-color: transparent;

    &:focus {
        outline-color: #f8b195;
    }
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    height: 3px;
    background: var(--secondary-color);
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border: 7px solid var(--secondary-color);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: relative;
    bottom: 5px;
    background: var(--text-color) center no-repeat;
    background-size: 50%;
    box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.4);
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}


input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #f8b195;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: relative;
    bottom: 8px;
    background: #222 center no-repeat;
    background-size: 50%;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}

input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #f8b195;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: relative;
    bottom: 8px;
    background: #222 center no-repeat;
    background-size: 50%;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}