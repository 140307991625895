@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Italic Variants */
@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'fsprotext';
  src: url('./fonts/SFProText-HeavyItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'fsprotext', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}