.modal-options {
    width: 30vh;
    height: 30vh;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    flex-direction: column;
    justify-content: center;
}

.modal-options input[type="text"] {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-content-options {
    display: flex;
    flex-direction: column;
}

.modal-options button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.modal-options button:hover {
    background-color: #0056b3;
}

.modal-options button:last-child {
    margin-right: 0;
}