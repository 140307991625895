.main_content_saves {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
    align-items: center;
    margin-top: 20px;
}

.user_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: -webkit-fill-available;
    margin: 0px 23px;
}

.user_photo {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.user_name {
    color: var(--text-second);
    margin-left: 10px;
}

.menu_button {
    position: absolute;
    right: 23px;
    background-color: transparent;
    border: none;
}

.menu_button img {
    width: 30px;
}

.blur_background {
    filter: blur(5px);
}

/* menu */

.menu {
    position: fixed;
    top: 80px;
    right: 20px;
    background-color: #dfdfdfe1;
    box-shadow: 0 0px 50px 5px var(--accent-color);
    padding: 20px;
    z-index: 100;
    width: min-content;
    border: none;
    border-radius: 20px;
}

.menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 120px;
}

.menu ul li p {
    text-decoration: none;
    color: var(--text-second);
    text-align: center;
}

.menu ul li.active {
    width: 100%;
}

.menu ul li.active p {
    background-color: var(--secondary-color);
    color: var(--text-color);
    border-radius: 10px;
    padding: 10px;
    margin: 0px;
}

.menu-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.menu-open {
    z-index: 99;
}

.menu-close {
    display: none;
}

.menu-profile-photo {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    border-radius: 100%;
}

/* menu modal content */

.settings-form {
    display: flex;
    flex-direction: column;
    margin: 50px;
    margin-bottom: 100px;
    color: #fff;
}

.settings-form label,
.settings-form input {
    margin: 4px;
}

.settings-form input {
    background-color: #ffffff00;
    border: none;
    color: white;
    border-bottom: 1px solid #fff;
}

.settings-save {
    background: #388E3C;
    border: none;
    border-radius: 10px;
    padding: .5rem;
    margin: .5rem;
    color: #fff;
}

.settings-logout {
    background: #8e3838;
    border: none;
    border-radius: 10px;
    padding: .5rem;
    margin: .5rem;
    color: #fff;
}

.feedback-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
}

.feedback-form-label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #fff;
}

.feedback-form-textarea {
    width: -webkit-fill-available;
    height: 150px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-size: 14px;
    color: #fff;
    background-color: transparent;
    margin-bottom: 10px;
}

.feedback-form-file-input {
    display: block;
    margin: 10px 0;
    color: #fff;
}

.feedback-form-submit-button {
    width: -webkit-fill-available;
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.feedback-form-submit-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.feedback-form-submit-button:focus {
    outline: none;
}

.feedback-form-submit-button:active {
    background-color: #388e3c;
}

.help-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.help-form-label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #fff;
}

.help-form-input {
    width: -webkit-fill-available;
    ;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background-color: transparent;
}

.help-form-submit-button {
    width: -webkit-fill-available;
    display: inline-block;
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.help-form-submit-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.help-form-submit-button:focus {
    outline: none;
}

.help-form-submit-button:active {
    background-color: #388e3c;
}

.settings-from-left {
    display: flex;
    flex-direction: row;
}

.save-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, transform 0.2s;
}

.save-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.save-button:active {
    background-color: #388e3c;
    transform: scale(1);
}

.save-button:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
}

.number-input {
    width: -webkit-fill-available;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #4CAF50;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.number-input:focus {
    border-color: #45a049;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    outline: none;
}

.number-input::placeholder {
    color: #9e9e9e;
    font-style: italic;
}

.number-input:disabled {
    background-color: #f0f0f0;
    border-color: #ccc;
    cursor: not-allowed;
}

.custom-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-list-item {
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.custom-list-item p {
    margin: 0;
}

.refund-button {
    background-color: #ff4b5c;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.refund-button:hover {
    background-color: #e04050;
}