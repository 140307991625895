.fooder_control {
    position: fixed;
    bottom: 80px;
    left: 10px;
    right: 10px;
    background-color: var(--secondary-color);
    z-index: 11;
    border-radius: 10px;
}

.container_fooder_audio {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
}

.audio-info {
    display: flex;
}

.container_fooder_audio .content {
    display: flex;
    width: -webkit-fill-available;
    margin: 0rem 1rem;
}

.fooder_control_content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    margin: 0rem 1rem;
    margin-top: 10px;
}

.fooder_control_name {
    text-align: center;
    color: var(--text-color);
    font-size: var(--font-size-16);
    font-weight: var(--font-weight-400);
}

.fooder_buttons_control {
    display: flex;
}

.icon-f {
    width: 25px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(176deg) brightness(111%) contrast(101%);
}

.fooder_buttons_control .button-cont {
    background: transparent;
    border: none;
    padding: 0rem 1rem;
}

.container div button {
    margin: 10px 10px 0px 0px;
    background-color: #ffffff00;
    border: none;
}

.audio {
    width: -webkit-fill-available;
}

.icon {
    width: 30px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(176deg) brightness(111%) contrast(101%);
}

.save-time-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.save-time-button:hover {
    background-color: #0056b3;
}

.saved-message {
    color: white;
}

.images-container-shadow {
    display: flex;
}

.audio-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.audioTabButton {
    color: white;
    background: none;
    border: none;
    border: 2px solid #2D3844;
    border-radius: 6px;
    height: 35px;
    width: 35px;
    font-size: 19px;
    margin: 10px;
}

.activeAudio {
    color: white;
    background: none;
    border: none;
    border-radius: 6px;
    height: 35px;
    width: 35px;
    font-size: 19px;
    background-color: #2D3844;
    margin: 10px;
}

.tab-input {
    color: white;
    background: none;
    border: none;
    border-radius: 6px;
    height: 35px;
    width: 35px;
    font-size: 19px;
    background-color: #2D3844;
    margin: 10px;
}