.read_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.read_modal_content {
    background-color: var(--primary-color);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    justify-content: unset;
}

.read_modal_header {
    position: sticky;
    top: 0px;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    z-index: 1;
}

.read_modal_header h2 {
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-500);
    text-align: center;
}

.tabs {
    margin-top: 10vh;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1;
}

.tabs button {
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #1f2933;
    transition: all 0.3s ease;
    outline: none;
    border-radius: 20px;
    margin: 10px;
    color: #fff;
}

.tabs button:focus {
    outline: none;
}

.tabs button.active {
    border: 2px solid #1f2933;
    background-color: transparent;
    color: #1F2933;
}

.image_container {
    position: relative;
    display: inline-block;
    width: -webkit-fill-available;
    height: min-content;
}

.image_container img {
    border-radius: 0px 0px 20px 20px;
}

.image_container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 255, 0) 10%, rgba(0, 0, 0, 1) 80%);
    border-radius: 0px 0px 20px 20px;
    pointer-events: none;
}

.sticky-audio {
    position: sticky;
    top: 68.5px;
    bottom: 0px;
    background-color: var(--accent-color);
    z-index: 5;
}

.card_description, .card_sound {
    padding: 0 20px;
    color: var(--text-second);
}

.icon {
    width: 30px;
}

.icon-play {
    width: 55px;
}

.icon-play-fooder {
    width: 30px;
}

.book-image {
    width: -webkit-fill-available;
    height: auto;

}

.book-image-back {
    position: absolute;
    filter: blur(200px);
    width: 230px;
    height: auto;
    margin: 20px;
    border-radius: 25px;
}

.wave-bars-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    width: 230px;
    cursor: pointer;
}

.wave-bar {
    width: 2px;
    /* Adjust width as needed */
    background-color: #fff;
    /* Default color */
    transition: background-color 0.2s ease;
}

@media (min-width: 359px) {
    .wave-bars-container {
        width: 230px;
    }
}

@media (max-width: 360px) {
    .wave-bars-container {
        width: 180px;
    }
}

.wave-bar {
    width: 100%;
    background-color: #ccc;
    margin: 2px;
    border-radius: 10px;
    transition: height 0.3s ease;
}

.wave-progress {
    background-color: #4CAF50;
    height: 100%;
}

.close {
    position: absolute;
    left: 20px;
    font-size: 38px;
    color: white;
}

span {
    font-size: 15px;
    color: white;
    text-align: center;
}

.audio-file-name {
    font-weight: bold;
    margin: 0px;
    color: var(--text-second);
    text-align: center;
}

.saved-time p {
    color: #fff;
}

.select-tab {
    margin: 20px;
    width: 40vh;
}

.audio_list {
    width: -webkit-fill-available;
}

.container .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: -webkit-fill-available;
    margin-right: 10px;
}

.content-section {
    display: flex;
}

.content-section span {
    color: var(--text-second);
}

.top-5 {
    margin-top: 5px;
    width: -webkit-fill-available;
}

.audio-control-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    width: -webkit-fill-available;
}

.audio-control-right {
    display: flex;
}

.seek-bar {
    width: 100%;
    background: transparent;
    -webkit-appearance: none;
}

.seek-bar::-webkit-slider-runnable-track {
    height: 4px;
    background: #ccc;
    border-radius: 4px;
}

.seek-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -4px;
}

.seek-bar::-moz-range-track {
    height: 4px;
    background: #ccc;
    border-radius: 4px;
}

.seek-bar::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
}

.seek-bar::-ms-track {
    width: 100%;
    height: 4px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.seek-bar::-ms-thumb {
    width: 12px;
    height: 12px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
}